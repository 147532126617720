import React from 'react';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import './App.css';

function About() {
    return (
        <div>
            <h2>About Me</h2>
            <p>Under construction...</p>
        </div>
    );
}

function Projects() {
    return (
        <div>
            <h2>Projects</h2>
            <ul>
                <li className="project-item">
                    <a href="https://doge.pkasiewicz.pl" target="_blank" rel="noopener noreferrer">
                        Dog Breed Rater
                        <span className="tooltip">Simple app where you can vote which of two printed breeds is better and then u can see leaderboard of all of them.</span>
                    </a>
                </li>
                <li className="project-item">
                    <a href="http://movieclub.pkasiewicz.pl" target="_blank" rel="noopener noreferrer">
                        MovieClub
                        <span className="tooltip">A Java-web application where you can view videos, rate them
                            after you create an account, and comment on them</span>
                    </a>
                </li>
                <li className="project-item">
                    <a href="https://psa.pkasiewicz.pl" target="_blank" rel="noopener noreferrer">
                        Pallet Space Assistant
                        <span className="tooltip">Application that helps me in my work, it calculate how many pallets of specific types will fit into truck</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

function Contact() {
    return (
        <div>
            <h2>Contact</h2>
            <p>Email: ptrkasiewicz@gmail.com</p>
            <p>GitHub: <a href="https://github.com/Zwiru" target="_blank">GitHub Profile</a></p>
            <p>LinkedIn: <a href="https://www.linkedin.com/in/piotr-k-23aa56300/" target="_blank">LinkedIn Profile</a>
            </p>
        </div>
    );
}

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Piotr Kasiewicz</h1>
                    <nav>
                        <ul>
                            <li><Link to="/">About Me</Link></li>
                            <li><Link to="/projects">Projects</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<About/>}/>
                        <Route path="/projects" element={<Projects/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                    </Routes>
                </main>
                <footer>
                    © 2024 by PKasiewicz.pl
                </footer>
            </div>
        </Router>
    );
}

export default App;